
import React from "react"
//import { navigate } from "@reach/router"
import { navigate } from "gatsby"
import { isLoggedIn } from "../utils/auth"

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, location, ...rest } = this.props
    if (!isLoggedIn()) {
      navigate(`/cp/login`)
      return null;
    }
    return <Component {...rest} />
  }
}

export default PrivateRoute