import React, { useEffect, useState } from "react"
import { navigate} from "@reach/router"
import { Link } from 'gatsby'
import Error from './Error'
import ErrorMessage from './ErrorMessage'
import { Helmet } from 'react-helmet'
import { Auth } from 'aws-amplify'
import LoginSplashImg from '../assets/images/login-splash-img.png';
import {AvForm, AvField, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import { Button, Card, CardBody } from 'reactstrap';
import { createAffiliateRequest, getAffiliateInfoRequest } from '../model/affiliateService/affiliateServiceRequests';

const initialState = {
  username: ``,
  password: ``,
  email: '',
  phone_number: '',
  authCode: '',
  stage: 0,
  error: ''
}

class SignUp extends React.Component {
  state = initialState;


  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  createAffiliate = async(data) => {

    const affiliateInfo = await createAffiliateRequest(
      {
        username: data.userSub,
      }
      ).then(async (responseData)=>{
          // const newCustomerData = await getCustomerSubscription({username:data.username}).then(async (response) => {
          //     //console.log('new customer data:',newCustomerData)
          //     return response;
          // });
          // console.log('new customer data:',responseData);
          // return newCustomerData;
      }).catch((error) => {
          console.log(error);
      });
  console.log('new affiliate data:',affiliateInfo)
  return affiliateInfo;

  }

  signUp = async() => {
    const { username, password } = this.state
    try {
      const email = username;
      const signupData = await Auth.signUp({ username, password, attributes: { email }})
      console.log(signupData);
      this.setState({ error: null })
      this.setState({ stage: 1 })
      await this.createAffiliate(signupData);
    } catch (err) {
      this.setState({ error: err })
      console.log('error signing up...', err)
    }
  }

  confirmSignUp = async() => {
    const { username, authCode } = this.state
    try {
      await Auth.confirmSignUp(username, authCode)
      alert('Successfully signed up!')
      navigate("/cp/login")
    } catch (err) {
      this.setState({ error: err })
      console.log('error confirming signing up...', err)
    }
  }

  render() {

    let sigupform = null;


    return (
      <div>

<Helmet
          meta={[
            { name: 'description', content: 'Charitable Impact Sign Up' },
            { name: 'keywords', content: 'affiliate, sign up' },
          ]}
        >
          <title>User Sign Up</title>
          <html lang="en" />
        </Helmet>

<div class="row splash-section">
              <div class="col-md-12">
                <div class="container page-body-top-pane">

                  <div class="row d-flex">
                    <div class="col-lg-5 col-xl-5 form-pane section-pane mr-auto pb-3">


                      <h1 class="text-center text-lg-left font-weight-bold">
                        Affiliate new registration
                      </h1>
                      
                      
                      {this.state.error && 
                        <>
                          <Error errorMessage={this.state.error}/>
                          <ErrorMessage errorMessage={this.state.error}/>
                        </>}

                      {this.state.stage===0 &&
                      <AvForm onValidSubmit={e => {this.signUp(e)}} id='signUpForm' ref={c => (sigupform = c)}>
                      <div className="generic-form">
        <div className="generic-form-field">

{/*         


        <input
                onChange={this.handleUpdate}
                placeholder='Email'
                name='username'
                value={this.state.username}
                className="hs-input mb-3"
              /> */}

<AvField name="username" type="text" className="form-control hs-input mb-3" id="username" value={this.state.username} placeholder='Email Address' onChange={this.handleUpdate} validate={{
            required: {value: true, errorMessage: 'Please enter your email address.'}}} />

<AvField name="password" type="password" className="form-control hs-input mb-3" id="password" value={this.state.password} placeholder='Password' onChange={this.handleUpdate} validate={{
            required: {value: true, errorMessage: 'Please enter a password.'}}} />

              {/* <input
                onChange={this.handleUpdate}
                placeholder='Password'
                name='password'
                value={this.state.password}
                type='password'
                className="hs-input mb-3"
              /> */}

<Button type="submit" className="btn btn-primary btn-donate font-weight-bold mr-2">Sign up</Button>
{/* 
          <div className="btn btn-primary btn-donate font-weight-bold mr-2" onClick={this.signUp}>
            <span>Sign Up</span>
          </div> */}
          <Link to="/cp/login">Afiiliate sign in</Link><br />
        </div>
        </div>
        </AvForm>
                      }
                      {this.state.stage===1 &&
                      <div>
                        <Card>
                          <CardBody>
                            <h3>Thanks for registering! </h3>
                            A verification email has been sent to you.  Please click on the link to verify your email address.
                          </CardBody>
                        </Card>
                      </div>
                      }


                    </div>

                    <div class="col-lg-7 col-xl-6 mb-auto page-headerimg-pane">

                      <div class="d-none d-lg-block">
                        <img src={LoginSplashImg} class="img-fluid"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
      </div>
    )
  }
}


export default SignUp