import axios from 'axios';
const webserviceUrl = process.env.GATSBY_WEBSERVICE_URI_AFFILIATES;
const webserviceApiHostname= process.env.GATSBY_WEBSERVICE_API_HOSTNAME_AFFILIATES;

const apiUserAccessKey = process.env.GATSBY_API_USER_ACCESS_KEY;
const apiUserSecretKey = process.env.GATSBY_API_USER_ACCESS_SECRET;

//console.log(apiUserAccessKey)
//console.log(apiUserSecretKey)


let aws4  = require('aws4');

const requestApi = async (payload)=>{

  console.log(payload)

    const {
     // webserviceHostname,
      webserviceBaseUrl,
      webApiPath,
      webMethod,
      webData,
      webApiEndpointPath} = payload;

    let axiosOptions = {
      // `axios` options
      baseURL: webserviceBaseUrl + webApiPath +webApiEndpointPath,
      url: webserviceBaseUrl + webApiPath +webApiEndpointPath,
      service: "execute-api",
      region: "us-west-2",
      path: '/'+webApiPath + webApiEndpointPath,
      data: webData, // aws4 looks for body; axios for data
      body: JSON.stringify(webData), // aws4 looks for body; axios for data
      method: webMethod,
      headers: {
        'content-type': 'application/json'
      }
    }
    let signedRequest = aws4.sign(axiosOptions, {accessKeyId: apiUserAccessKey, secretAccessKey: apiUserSecretKey});


    delete signedRequest.headers['Host']
    delete signedRequest.headers['Content-Length']

    let responseData = await axios(signedRequest, res=>{
      console.log(res)
      return res;
    });
    return responseData;
    //return setup(requestOpts);
}

export async function affiliateServicesApi (apiEndpointPath='',data={},method) {
  // Register the custom `memoryDriver` to `localforage`
  //await localforage.defineDriver(memoryDriver);
  const affiliateApiPath = process.env.GATSBY_API_PATH_AFFILIATES;
  const payload = {
    webserviceHostname: webserviceApiHostname,
    webserviceBaseUrl: webserviceUrl,
    webApiPath: affiliateApiPath,
    webApiEndpointPath: apiEndpointPath,
    webData: data,
    webMethod: method,
  }
  return await requestApi(payload);
}