import React from "react"
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { setUser, isLoggedIn } from '../utils/auth'
import Error from './Error'
import ErrorMessage from './ErrorMessage'
import { Helmet } from 'react-helmet'
import { Auth } from 'aws-amplify'
import LoginSplashImg from '../assets/images/login-splash-img.png';
import {AvForm, AvField, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    error: ``
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  login = async() => {
    const { username, password } = this.state
    try {
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes,
        username: user.username
      }
      setUser(userInfo)
      navigate("/cp/home")
    } catch (err) {
      this.setState({ error: err })
      console.log('error...: ', err)
    }
  }

  render() {
    console.log(this.props)
    if (isLoggedIn()) navigate('/cp/profile')

    let signinform = null;

    return (
      <div>
        <Helmet
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <title>User Login</title>
          <html lang="en" />
        </Helmet>


        <main class="main-body pb-5">
      <div class="container">
        <section>
          <div class="row d-flex justify-content-between">
            <div class="col-md-5">
              <div class="body-text-pane">
                <div class="form-pane section-pane">
                
                 
                <h1 class="text-center text-lg-left font-weight-bold">
                        Affiliate Sign In
                      </h1>
                      
                      {this.state.error && 
                        <>
                          <Error errorMessage={this.state.error}/>
                          <ErrorMessage errorMessage={this.state.error}/>
                        </>}


                      <AvForm onValidSubmit={e => {this.login(e)}} id='signInForm' ref={c => (signinform = c)}>

                      
                      <div className="generic-form">
        <div className="generic-form-field">

        <AvField name="username" type="text" className="form-control hs-input mb-3" id="username" value={this.state.username} placeholder='Email Address' onChange={this.handleUpdate} validate={{
            required: {value: true, errorMessage: 'Please enter your email address.'}}} />


<AvField name="password" type="password" className="form-control hs-input mb-3" id="password" value={this.state.password} placeholder='Password' onChange={this.handleUpdate} validate={{
            required: {value: true, errorMessage: 'Please enter a password.'}}} />
        
        <Button type="submit" className="btn btn-primary btn-donate font-weight-bold mr-2">Sign in</Button>
          {/* <div className="btn btn-primary btn-donate font-weight-bold mr-2" onClick={this.login}>
            <span>Sign In</span>
          </div> */}
          <Link to="/cp/signup">Afiiliate sign up</Link><br />
        </div>

        </div>
        </AvForm>

                </div>
              </div>
            </div>
            <div class="col-md-6 text-center text-md-right">
             <div class="body-bg-img-pane" style={{backgroundImage: "url(" + LoginSplashImg +")"}}>

             </div>
            </div>
          </div>
        </section>

       
          </div>

    

    </main>



       
      </div>
    )
  }
}

// const styles = {
//   input: {
//     height: 40, margin: '10px 0px', padding: 7
//   },
//   formContainer: {
//     display: 'flex', flexDirection: 'column'
//   },
//   button: {
//     backgroundColor: 'rebeccapurple', padding: '15px 7px', cursor: 'pointer', textAlign: 'center', marginBottom: 10
//   },
//   buttonText: {
//     color: 'white'
//   }
// }

export default Login