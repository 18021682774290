import { affiliateServicesApi } from "./affiliateService";

export async function createAffiliateRequest (requestBody) {
  console.log(requestBody)
  const apiEndpointPath = 'affiliates/create_affiliate';
  const resultData = affiliateServicesApi(apiEndpointPath,requestBody,'post').then(async (response) => {
    if(response.data){
      console.log(response.data);
      return response.data;
    }
    else return [];
  });
  return resultData;
}



export async function getAffiliateInfoRequest (requestBody) {
  console.log(requestBody)
  const apiEndpointPath = 'affiliates/get_affiliate_info';
  const resultData = affiliateServicesApi(apiEndpointPath,requestBody,'post').then(async (response) => {
    if(response.data){
      console.log(response.data);
      return response.data;
    }
    else return [];
  });
  return resultData;
}