//https://github.com/wesbos/dump
import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';

const Error = props => {

  console.log(props);

  const { code, message, name } = props.errorMessage;

  return (
    <div className="my-3">
    <Card>
      <CardBody>
        {((code === 'InvalidParameterException' && message.includes("'password' failed to satisfy constraint")) || (code === 'InvalidPasswordException')) &&
          <span className="text-danger">Your password has to be at least 7 characters in length and contains at least 1 uppercase and 1 lowercase letter.</span>
        }

        {((code === 'InvalidParameterException' && message.includes("Invalid email address"))) &&
          <span className="text-danger">Your email address is invalid.</span>
        }

        {(code === 'UsernameExistsException') &&
          <span className="text-danger">Your email address has already been used for registration. Please use another email address.</span>
        }

        {(code === 'UserNotFoundException' || code === 'NotAuthorizedException') &&
          <span className="text-danger">Invalid login credentials.  Please try again.</span>
        }
      </CardBody>
    </Card>
    </div>
);
}


export default Error;